<template>
  <v-form ref="form">
    <v-container>
      <v-text-field
        v-model="project.name"
        label="Project Name"
        required
        :rules="[v => !!v && v.length > 0 || 'Project Name is required']"
      ></v-text-field>

      <v-text-field
        v-model="project.projectId"
        label="ProjectId"
        required
        readonly
      ></v-text-field>

      <v-autocomplete
        v-model="project.organizationId"
        :items="organizations"
        label="Organization"
        item-text="name"
        item-value="_id"
      ></v-autocomplete>

      <v-textarea
        v-model="project.description"
        label="Project Description"
        required
        :rules="[v => !!v && v.length > 0 || 'Project Description is required']"
        rows="3"
      ></v-textarea>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="project.start"
            label="Start Date"
            prepend-icon="event"
            readonly
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker
          :rules="[v => !!v && v.length > 0  || 'Start date is required']"
          required
          v-model="project.start"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="$refs.menu.save(date)">OK</v-btn>
        </v-date-picker>
      </v-menu>

      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="project.expiry"
            label="End Date"
            prepend-icon="event"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :rules="[v => !!v && v.length > 0 || 'Expiry date is required']"
          v-model="project.expiry"
          no-title
          scrollable
          required
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$refs.menu2.save(dates)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      
      <v-autocomplete
        v-model="project.status"
        :items="status"
        label="Status"
        item-text="name"
        item-value="_id"
        required
        :rules="[v => !!v || 'Status is required']"
      ></v-autocomplete>

      <v-btn @click="$router.push({ name: 'MobileProjectIndex' })" color="primary mr-2">Back</v-btn>
      <v-btn @click="updateProject()" class="primary mr-2" v-if="type == 'UPDATE'">Update</v-btn>
      <v-btn @click="addItem" class="primary mr-2">Add Activity</v-btn>
      <v-btn @click="createProject()" class="primary" v-if="type == 'CREATE'">Create</v-btn>
    </v-container>

    <div class="mt-5 ml-3 text-center" v-if="undefined != project.activities.length && project.activities.length != 0">
      <p class="title text-xs-center">Project has {{ project.activities.length }} activity</p>
    </div>

    <v-expansion-panels class="pa-4">
      <v-expansion-panel v-for="(activities, ridx) in project.activities" :key="ridx">
        <v-expansion-panel-header>Activity {{ ridx + 1 }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn color="primary mb-3" @click="removePanel(activities, ridx)">Remove</v-btn>

          <v-autocomplete
            v-model="project.activities[ridx].activityId"
            :items="activityList"
            label="Activity"
            item-text="alias"
            item-value="_id"
            required
            :rules="[v => !!v || 'Activity is required']"
          />
 
          <v-text-field
            v-model="project.activities[ridx].activityId"
            label="ActivityId"
            required
            readonly
          ></v-text-field>

          <v-text-field
            v-model="project.activities[ridx].points"
            label="Points"
            persistent-hint
            hint="Number greater than zero only"
          ></v-text-field>
        
          <v-text-field
            v-model="project.activities[ridx].startInterval"
            label="Start Interval"
            persistent-hint
            hint="Default 0 if does not require delay start"
          ></v-text-field>

          <v-text-field
            v-model="project.activities[ridx].expiryInterval"
            label="Expiry Interval"
            persistent-hint
            hint="Default 0 if does not require validity length date"
          ></v-text-field>

          <v-text-field
            label="Project Info Page URL"
            v-model="project.activities[ridx].projectInfoPageUrl"
            persistent-hint
            hint="URL has to begin with 'http://www'"
          ></v-text-field>

          <v-select
            label="Recurring"
            v-model="project.activities[ridx].recurring"
            :items="[ true, false ]"
          ></v-select>

          <v-select
            label="Demographic"
            v-model="project.activities[ridx].demographic"
            :items="[ true, false ]"
          ></v-select>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();
export default {
  props: {
    type: String,
    project: {
      activities: Array
    }
  },
  data() {
    return {
      status: [
        { _id: "A", name: "Active" },
        { _id: "I", name: "Inactive" }
      ],
      valid: true,
      activityList: [],
      date: new Date().toISOString().substr(0, 10),
      dates: new Date().toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      organizations: []
    };
  },

  mounted() {
    this.loadActivities();
    this.fetchOrganizations();
  },

  methods: {
    loadActivities() {
      service.loadActivities().then(r => {
        this.activityList = r.data
      });
    },

    addItem() {
      this.project.activities.push({});
    },

    removePanel(ridx, stidx) {
      this.project.activities.splice(stidx, 1);
    },

    updateProject() {
      if (this.$refs.form.validate()) {
        this.$setLoader();

        service.updateProject(this.project).then(res =>{
          this.$showSuccessMessage(res.data)
        })
      }      
    },

    createProject() {
      if (this.$refs.form.validate()) {
        this.$setLoader();

        service.createProjectApp(this.project).then(res => {
          this.$showSuccessMessage(res.data)
          this.$router.push({ name: "MobileProjectIndex" });
        })
      }
    },

    fetchOrganizations() {
      service.fetchOrganizations().then(r => {
        this.organizations = r.data.organizations;
      });
    }
  }
};
</script>